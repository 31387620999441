<template>
  <div class="Banner">
    <img src="../assets/spamfunlogo.png" class="insideimg" />
    <img src="../assets/theshadow.png" class="shadow" />
  </div>
</template>

<script>
export default {};
</script>

<style>
.Banner {
  max-width: 40%;
  height: auto;
  margin: 0px;
  top: 50%;
  opacity: 0;
  animation: Fade 1s forwards 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.insideimg {
  width: 100%;
  height: 100%;
  margin: 0px;
}
.shadow {
  position: fixed;
  height: auto;
  width: 547%;
  transform: translateX(-100%);
  z-index: -100;
  overflow: visible;
}
.Quote {
  color: white;
  animation: JustFade 1s forwards 1s;
}

@keyframes JustFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes Fade {
  from {
    opacity: 0;
    transform: translate(-500%, 500%);
  }
  to {
    opacity: 100;
  }
}
</style>