<template>
  <div class="Details">
    Spam that you are going to LOVE. Coming Real Soon. Connect:
    <br />
    <div class="content">
      <div class="Connect">
        <a
          target="_blank"
          class="bi bi-discord"
          href="https://discord.gg/mF3c84W"
        ></a>
      </div>
      &nbsp; &nbsp;

      <div class="Connect">
        <a
          target="_blank"
          class="bi bi-instagram"
          href="https://www.instagram.com/spamfungames/"
        ></a>
      </div>
      <div class="Connect">
        <a
          target="_blank"
          class="bi bi-play-fill"
          href="https://play.google.com/store/apps/dev?id=9060737679225670696"
        ></a>
      </div>
      <div class="Connect">
        <a
          target="_blank"
          class="bi bi-youtube"
          href="https://www.youtube.com/channel/UCVIDaOorxSVMIxsJGKcgQrQ"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.Details {
  color: white;
  font-family: "Staatliches", cursive;
  opacity: 0;
  animation: Fade1 1s forwards 2s;
  font-size: 4vh;
  z-index: 10;
}
.content {
  display: flex;
  justify-content: center;
}
.Connect {
  transition: 0.2s;
  font-size: 80px;
  text-decoration: none;
  color: white;
}
.Connect:hover {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
}

a:link {
  color: white;
}
a:visited {
  color: white;
}
a:hover {
  color: white;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

@keyframes Fade1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
</style>