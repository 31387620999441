<template>
  <div id="app" class="bg">
    <div class="bg2"></div>
    <MainLogo></MainLogo>
    <Details></Details>
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> | -->
      <!-- <router-link to="/about">About</router-link> -->
    </div>
    <!-- <router-view /> -->
  </div>
</template>

<script>
import MainLogo from "./components/MainLogo.vue";
import Details from "./components/Details.vue";

export default {
  components: {
    MainLogo,
    Details,
  },
};
</script>





<style>
body {
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.bg {
  background-image: url("./assets/BG.png");
  background-image: linear-gradient(
    to right,
    #ff009c,
    #fb00b1,
    #f200c9,
    #e100e3,
    #c700ff
  );
  position: absolute;
  object-fit: cover;
  background-repeat: repeat-y;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  z-index: -1000;
  margin: 0px;
}
.bg2 {
  background-image: url("./assets/funtile.png");
  animation: Loop 10s infinite linear;
  position: absolute;
  object-fit: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  z-index: -1000;
  margin: 0px;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

@keyframes Loop {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 1000px 2000px;
  }
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
